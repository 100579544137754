import { graphql } from 'gatsby'
import { FormattedMessage, injectIntl } from 'gatsby-plugin-intl'
import { IntlShape } from 'gatsby-plugin-intl'
import React from 'react'
import {
  Box,
  Container,
  Footer,
  Image,
  Layout,
  Navbar,
  Reveal,
  Text,
} from '../components'

export const query = graphql`
  query {
    topBackgroundImage: file(relativePath: { eq: "top_background.jpg" }) {
      ...getImage
    }

    imageTravel: file(relativePath: { eq: "image-travel.jpg" }) {
      ...getImage
    }
  }
`

interface ITravelPageProps {
  data: {
    topBackgroundImage: any
    imageTravel: any
  }

  intl: IntlShape
}

const travel = (props: ITravelPageProps) => {
  return (
    <Layout
      backgroundImage={`url(${props.data.topBackgroundImage.publicURL})`}
      backgroundSize='80% auto '
      backgroundPosition='top center'
    >
      <>
        <Navbar />

        <Container>
          <Box padding='3em 0em 0em 0em'>
            <Reveal>
              <Image
                src={props.data.imageTravel.publicURL}
                radius='0.2em'
                style={{ width: '100%' }}
                tilt
              />
            </Reveal>
          </Box>

          <Box padding='5em 0em 0em 0em'>
            <Reveal>
              <>
                <Box>
                  <Text color='white' weight={300} size='2.5em'>
                    {props.intl.formatMessage({ id: 'travel.title1' })}
                  </Text>
                </Box>

                <Box padding='1.2em 0em 0em 0em'>
                  <Text
                    color='white'
                    weight={300}
                    size='1.5em'
                    lineHeight={1.75}
                  >
                    {props.intl.formatMessage({ id: 'travel.content1.p1' })}
                  </Text>
                </Box>

                <Box padding='0.7em 0em 0em 0em'>
                  <Text
                    color='white'
                    weight={300}
                    size='1.5em'
                    lineHeight={1.75}
                  >
                    {props.intl.formatMessage(
                      { id: 'travel.content1.p2' },
                      {
                        a: (...chunks) => (
                          <a href={`mailto:${chunks}`}>
                            <Text color='lightPurple'>{chunks}</Text>
                          </a>
                        ),
                      },
                    )}
                  </Text>
                </Box>

                <Box padding='0.7em 0em 0em 0em'>
                  <Text
                    color='white'
                    weight={300}
                    size='1.5em'
                    lineHeight={1.75}
                  >
                    {props.intl.formatMessage({ id: 'travel.content1.p3' })}
                  </Text>
                </Box>
              </>
            </Reveal>
          </Box>

          {/*<Box padding='2em 0em 0em 0em'>
            <Reveal>
              <Image
                src={props.data.imageDeparture.publicURL}
                radius='0.2em'
                style={{ width: '40%' }}
                tilt
              />
            </Reveal>
  </Box>*/}
        </Container>

        <Box padding='12em 0em 8em 0em' paddingSm='8em 0em 8em 0em'>
          <Reveal>
            <Footer />
          </Reveal>
        </Box>
      </>
    </Layout>
  )
}

export default injectIntl(travel)
